import React from "react";

class WpAdmin extends React.Component {

  componentDidMount() {
    const windowGlobal = typeof window !== 'undefined' && window;
    windowGlobal.location = 'https://thehelm.polb.com/thehelm/';
  }

  render() {
    return (
      <p></p>
    );
  }
}

export default WpAdmin;